import updateHead from "../../utils/helper/updatePageHead";
import PricingCard from "./tmpl/PricingCard";
import Footer from "../Footer/Footer";
import { PeasysClassic, PeasysPremium, PeasysFreeTrial, servicePricingInfos } from "../../data/Pricing Plan/PricingCardInfo";
import { Link } from "react-router-dom";
import PackCard from "./tmpl/PackCard";
import { Install, Support_1, Support_2, Support_3 } from "../../data/Pricing Plan/PackCardInfos";

export default function Pricing() {
    const title = "DIPS | Nos tarifs";
    const cssPath = "/style/pricing.css";

    return (
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <section className="section-centered-layout">
                            <header className="section-pricing-title-container">
                                <h1 className="section-pricing-title">Envie de collaborer avec DIPS pour vos projets ?</h1>
                            </header>
                            <div className="section-centered-layout">
                                <PricingCard infos={servicePricingInfos} />
                            </div>
                        </section>

                        <section>
                            <div className="section-centered-layout">
                                <header className="section-pricing-title-container">
                                    <h1 className="section-pricing-title">Pour continuer avec Peasys...</h1>
                                    <h2 className="section-pricing-subtitle">
                                        Besoin d'une clé de licence Peasys? Créez-la ici. Si vous souhaitez changer
                                        d'abonnement sur une clé existante, visitez <Link to='/account' style={{ display: 'inline' }}>votre compte</Link>.
                                    </h2>
                                </header>
                            </div>

                            <div className="section-columns-layout" grid-template="1,1,1" style={{ gap: '1rem' }}>
                                <PricingCard infos={PeasysFreeTrial} />
                                <PricingCard infos={PeasysClassic} />
                                <PricingCard infos={PeasysPremium} />
                            </div>
                        </section>

                        <section className="section-centered-layout">
                            <div className="section-centered-layout">
                                <header className="section-pricing-title-container">
                                    <h1 className="section-pricing-title">Besoin d'un coup de main avec Peasys ?</h1>
                                    <h2 className="section-pricing-subtitle">
                                        Profitez de notre expérience grâce à nos différents packs. <Link to='/contact'>Contactez-nous</Link> afin que nous
                                        puissons approfondir votre demande.
                                    </h2>
                                </header>
                            </div>

                            <div className="section-columns-layout" grid-template="1,1">
                                <div>
                                    <PackCard data={Install} />
                                </div>
                                <div className="section-row-layout" style={{ gap: '.5rem' }}>
                                    <PackCard data={Support_1} />
                                    <PackCard data={Support_2} />
                                    <PackCard data={Support_3} />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}