import updateHead from "../../utils/helper/updatePageHead";
import { checkEmptyInput } from "../../utils/helper/inputHelper";
import { useState } from "react";
import { checkLogin } from "../../utils/api/userFetchs";
import { useUser } from "../../utils/contexts/userContext";
import { Link } from "react-router-dom";

export default function Login() {
    const title = "DIPS | Connectez-vous à votre compte";
    const cssPath = "/style/login.css";

    const { loginLocalUser } = useUser();

    const [errorMessage, setErrorMessage] = useState("");
    const [infos, setInfos] = useState({
        mail: "",
        password: ""
    })

    const logUser = async (e) => {
        e.preventDefault();
        if (!infos.mail || !infos.password) {
            setErrorMessage("Les champs doivent être renseignés")
        }

        const sessionInfos = await checkLogin(infos)
        if (!sessionInfos) {
            setErrorMessage("Email et/ou mot de passe invalides")
            return;
        }

        loginLocalUser(sessionInfos.user, sessionInfos.token)
        window.history.back()
    }
    ///password-recovery
    return (
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-row-layout">
                            <header>
                                <h1 className="section-content-title">Connexion</h1>
                                <h2 className="section-content-subtitle">Connectez-vous à votre espace personnalisé</h2>
                            </header>
                            <div className="section-row-layout section-info-container" style={{ width: 'unset' }}>
                                <img src="/assets/logo_dips.svg" alt="company logo" className="icon logo-full"/>
                                <div className="section-centered-layout">
                                    <form className="section-row-layout" onSubmit={logUser}>
                                        <div>

                                            <label>E-mail</label>
                                            <input
                                                type="text"
                                                name="mail"
                                                onChange={(e) => setInfos((previous) => ({ ...previous, "mail": e.target.value }))}
                                                onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                                            <label>Mot de passe</label>
                                            <input
                                                type="password"
                                                name="password"
                                                onChange={(e) => setInfos((previous) => ({ ...previous, "password": e.target.value }))}
                                                onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                                            <span style={{ fontSize: '14px', display: 'flex', float: 'right' }}><Link to='/password-recovery'>Mot de passe oublié ?</Link></span>
                                        </div>
                                        <div>
                                            <p className="error-text">{errorMessage}</p>
                                            <button className="dark-bg">Se connecter</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}