import updateHead from "../../../utils/helper/updatePageHead";
import CodeBlockWrapper from "../../Utils/CodeBlockWrapper";
import { createQuery, deleteQuery, insertQuery, updateQuery, alterQuery, dropQuery, selectQuery } from "../../../data/Documentation/QueriesData"

export default function DocSqlQueries() {
    const title = "DIPS | Documentation Peasys";
    const cssPath = "/style/documentation.css";
    return (
        <>
            {updateHead({ title, cssPath })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <section className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Intéragissez avec la base de données de votre IBMi (DB2)</h1>
                                <h2 className="section-content-subtitle">Et gagnez en portabilité dans vos développements</h2>
                            </header>
                            <p>
                                En suivant le principe IBM <a href="https://www.volubis.fr/news/liens/courshtm/V7.2/SQLasaService.html">SQL As A Service</a>, Peasys vous permet l'exécution d'instructions SQL directement 
                                sur votre serveur et la récupération des données correctement formatées directement dans votre programme client.
                            </p>
                        </section>

                        <section id="section-sql-create" className="section-content-container">
                            <h1 className="section-subtitle-special">Executez une requête CREATE SQL</h1>
                            <p >
                                Créer une nouvelle table SQL et récupérer les informations relatives à votre requête.
                            </p>
                            <CodeBlockWrapper
                                data={createQuery}
                                showLineNumbers={true} />
                        </section>

                        <section id="section-sql-delete" className="section-content-container">
                            <h1 className="section-subtitle-special">Executez une requête DELETE SQL</h1>
                            <p >
                                Supprimer une table SQL et récupérer les informations relatives à votre requête.
                            </p>
                            <CodeBlockWrapper
                                data={deleteQuery}
                                showLineNumbers={true} />
                        </section>

                        <section id="section-sql-insert" className="section-content-container">
                            <h1 className="section-subtitle-special">Executez une requête INSERT SQL</h1>
                            <p >
                                Insérer des nouvelles valeurs dans une table SQL et récupérer les informations relatives à votre requête.
                            </p>
                            <CodeBlockWrapper
                                data={insertQuery}
                                showLineNumbers={true} />
                        </section>

                        <section id="section-sql-select" className="section-content-container">
                            <h1 className="section-subtitle-special">Executez une requête SELECT SQL</h1>
                            <p >
                                Sélectionner des entrées dans une table SQL et récupérer les informations relatives à votre requête.
                            </p>
                            <CodeBlockWrapper
                                data={selectQuery}
                                showLineNumbers={true} />
                        </section>

                        <section id="section-sql-alter" className="section-content-container">
                            <h1 className="section-subtitle-special">Executez une requête ALTER SQL</h1>
                            <p >
                               Modifier une table SQL et récupérer les informations relatives à votre requête.
                            </p>
                            <CodeBlockWrapper
                                data={alterQuery}
                                showLineNumbers={true} />
                        </section>

                        <section id="section-sql-update" className="section-content-container">
                            <h1 className="section-subtitle-special">Executez une requête UPDATE SQL</h1>
                            <p >
                                Mettre à jour des nouvelles valeurs dans une table SQL et récupérer les informations relatives à votre requête.
                            </p>
                            <CodeBlockWrapper
                                data={updateQuery}
                                showLineNumbers={true} />
                        </section>

                        <section id="section-sql-drop" className="section-content-container">
                            <h1 className="section-subtitle-special">Executez une requête DROP SQL</h1>
                            <p >
                                Supprimer une table de votre base de données et récupérer les informations relatives à votre requête.
                            </p>
                            <CodeBlockWrapper
                                data={dropQuery}
                                showLineNumbers={true} />
                        </section>
                    </div>
                </div>
            </section>
        </>
    )
}