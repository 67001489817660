export const Install = {
    title : "Pack Installation Peasys",
    description : "Installation et configuration du module Peasys serveur IBMi et premier pas avec les APIs.",
    price : 250,
    duration : "4h"
}

export const Support_1 = {
    title : "Support Peasys Courte durée",
    description : "Accompagnement sur vos premiers développement avec Peasys et dépannages.",
    price : 250,
    duration : "4h"
}

export const Support_2 = {
    title : "Support Peasys Classique",
    description : "Accompagnement sur un projet applicatif : révision et support.",
    price : 1200,
    duration : "3jours"
}

export const Support_3 = {
    title : "Support Peasys Longue durée",
    description : "Approfondissement et développement de nouvelles fonctionnalités.",
    price : 1800,
    duration : "5jours"
}