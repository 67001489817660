import { Link } from "react-router-dom";
import { useUser } from "../../../utils/contexts/userContext"

export default function PricingCard({ infos }) {
    const { user } = useUser();


    var link = user._id ? "/payement?pricing-plan=" + infos.pricing_plan : "/register"
    if (infos.pricing_plan === '1' || infos.pricing_plan === '4') link = '/appointement-schedule';

    return (
        <div className="section-info-container">

            <header>
                <h2 className="section-pricing-card-title">{infos.title}</h2>
                <span className="section-pricing-card-subtitle">{infos.subtitle}</span>
            </header>

            {(infos.pricing_plan !== '1' && infos.pricing_plan !== '4') &&
                <div style={{ margin: '25px 0' }}>
                    <span style={{ fontSize: '45px' }}>{infos.price}€</span>
                    <span style={{ fontSize: '12px' }}> HT/connexion/mois</span>
                </div>
            }
            {(infos.pricing_plan === '1' || infos.pricing_plan === '4') &&
                <div style={{ margin: '25px 0' }}>
                    <span style={{ fontSize: '45px' }}>Devis</span>
                </div>
            }

            <div className="section-pricing-payement-button">
                <Link to={link}>{infos.button_text}</Link>
            </div>

            <div className="br-line" />
            <span style={{ fontSize: '14px' }}>{infos.description_title}</span>
            <ul>
                {infos.descritpion_list.map((info, index) =>
                (<li key={info + index} className="section-pricing-card-description-item">
                    <img src="/assets/hexagon-check.svg" alt="check" style={{ width: '20px' }} />
                    <span>{info}</span>
                </li>))}
            </ul>
        </div >
    )
}