import AskForRecovery from "./SubComponents/AskForRecovery"
import ResetPassword from "./SubComponents/ResetPassword"
import TokenInput from "./SubComponents/TokenInput"
import { useState } from 'react'

export default function PasswordRecovery() {
    const [content, setContent] = useState('AskForRecovery')
    const [email, setEmail] = useState('')

    return (
        <section className="section">
            <div className="section-container">
                <div className="section-container-layout">
                    <div className="section-row-layout">
                        {(() => {
                            switch (content) {
                                case 'AskForRecovery':
                                    return <AskForRecovery changeContent={setContent} email={email} changeEmail={setEmail} />
                                case 'TokenInput':
                                    return <TokenInput changeContent={setContent} email={email} />
                                case 'ResetPassword':
                                    return <ResetPassword email={email} />
                                default:
                                    return <AskForRecovery changeContent={setContent} email={email} changeEmail={setEmail} />
                            }
                        })()}
                    </div>
                </div>
            </div>
        </section>
    )
}