import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <>
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-columns-layout" grid-template="1,1">
                            <img className='icon' src="/assets/404_not_found.svg" alt="not found"/>
                            <div className="section-vertical-centered-layout">
                                <h1 style={{ fontSize: '64px', lineHeight: '1.2' }}>Mince, il semble que rien ne corresponde à votre recherche...</h1>
                                <Link to=''>
                                    <button className="dark-bg">Revenir sur la page d'accueil</button>
                                </Link>
                            </div>
                            {/*Image de <a href="https://fr.freepik.com/vecteurs-libre/illustration-isometrique-erreur-404_7740
                            133.htm#page=2&query=Not%20Found%20isometric&position=0&from_view=search&track=ais&uuid=b8b1d417-c2e4-4005-8a0b-360d44d02898">Freepik</a>*/}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}