import OtpInput from 'react-otp-input';
import { useState, useEffect} from 'react'
import { checkOtp, sendOtp } from '../../../utils/api/userFetchs';

export default function TokenInput({ changeContent, email }) {
    const [otp, setOtp] = useState('');
    const [errorMessage, setErrorMessage] = useState('')

    function checkToken(e) {
        e.preventDefault()

        checkOtp(email, otp).then(({ code, message }) => {
            code === 200 ? changeContent('ResetPassword') : setErrorMessage(message);
        })
    }

    function resendOtp() {
        if(counter > 0){
            return
        }
        setResendText('Renvoyé !')
        sendOtp(email).then(({ code, message }) => {
            code === 200 ? changeContent('TokenInput') : setErrorMessage(message)
        })
    }

    const [counter, setCounter] = useState(5);
    const [resendText, setResendText] = useState('Renvoyer ' + counter)

    useEffect(() => {
        const interval = setInterval(() => {
            if(counter > 0 ){
                setCounter(counter - 1)
                setResendText('Renvoyer ' + counter)
            } else {
                clearInterval(interval)
                setResendText('Renvoyer')
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [counter]);

    return (
        <div className="section-info-container centered-info-container">
            <img src="/assets/logo_dips.svg" alt="company logo" className="icon logo-full" />
            <p>Renseignez le code reçu par e-mail.</p>
            <div className="section-centered-layout">
                <form className="section-row-layout" onSubmit={checkToken}>
                    <OtpInput
                        containerStyle={{ display: 'grid', columnGap: '1em', gridTemplateColumns: 'repeat(6, 1fr)' }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                    />
                    <div>
                        <p className='error-text'>{errorMessage}</p>
                        <button className="dark-bg">Vérifier le code</button>
                        <span style={{ fontSize: '13px', display: 'flex', margin: '5px 10px' }}>
                            Vous n'avez pas reçu de code ? &nbsp;
                            <span onClick={resendOtp} style={{fontWeight:'700', color:'gray'}}>{resendText}</span>
                        </span>
                    </div>
                </form>
            </div>
        </div>

    )
} 