import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from './utils/contexts/userContext';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe("pk_live_51ORsa6I27ExSp7sFwVHo3OUMXEdT3IfU3G1WljcvGCwxi2MY9TTDoluCZy9Uy9t7pirfLhPf8Outeg0IkWiRvtqp009nPY7SKH")

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <UserProvider>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </UserProvider>
  </BrowserRouter>
);
