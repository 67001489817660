import NavBar from "./components/NavBar/NavBar";
import Payement from "./components/Payement/Payement";
import Home from "./components/Home/Home"
import Services from "./components/Services/Services";
import Company from "./components/Company/Company";
import Pricing from "./components/Pricing/Pricing";
import Documentation from "./components/Documentation/Documentation";
import Library from "./components/Library/Library";
import Products from "./components/Products/Products";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Account from "./components/Account/Account";
import NavBarCollapsed from "./components/NavBar/NavBarCollapsed";
import Contact from "./components/Contact/Contact";
import ScrollToTop from "./components/Utils/ScrollToTop";
import NotFound from "./components/NotFound/NotFound";
import PayementStatus from "./components/Payement/PayementStatus";
import GeneralConditions from "./components/General Conditions/GeneralConditions";
import PasswordRecovery from "./components/Password Recovery/PasswordRecovery";
import { Route, Routes, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import AppointementSchedule from "./components/Contact/AppointementSchedule";

function App() {

  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  window.addEventListener('resize', () => { setWindowWidth(window.innerWidth) })

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false)
    }, 500);
    setIsLoading(false)

    return () => clearTimeout(timeout);
  }, [location])

  return (
    <>
      <ScrollToTop />
      {windowWidth > 900 ? <NavBar /> : <NavBarCollapsed />}
      {isLoading ?
        (<h1>loading</h1>) :
        (<Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/library" element={<Library />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/company" element={<Company />} />
          <Route exact path="/payement" element={<Payement />} />
          <Route exact path="/payement/:licenseKeyId" element={<PayementStatus />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/appointement-schedule" element={<AppointementSchedule/>} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/general-conditions" element={<GeneralConditions/>} />
          <Route exact path="/password-recovery" element={<PasswordRecovery/>} />
          <Route exact path="/account" element={<Account page={"dashboard"} />} />
          <Route exact path="/account/modification" element={<Account page={"modification"} />} />
          <Route exact path="/account/subscriptions" element={<Account page={"subscriptions"} />} />
          <Route exact path="/account/subscriptions/:licenseKeyId" element={<Account page={"subscriptions/subscription"} />} />
          <Route exact path="/account/statistics" element={<Account page={"statistics"} />} />
          <Route exact path="/account/admin-panel" element={<Account page={"admin-panel"} />} />
          <Route exact path="/account/contact" element={<Account page={"contact"} />} />
          <Route exact path="/account/deconnexion" element={<Account page={"deconnexion"} />} />
          <Route exact path="/docs" element={<Documentation page={"overview"} />} />
          <Route exact path="/docs/install" element={<Documentation page={"install"} />} />
          <Route exact path="/docs/connexion" element={<Documentation page={"connexion"} />} />
          <Route exact path="/docs/sql-query" element={<Documentation page={"sql-query"} />} />
          <Route exact path="/docs/ibmi-query" element={<Documentation page={"ibmi-query"} />} />
          <Route exact path="/docs/use-cases" element={<Documentation page={"use-cases"} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>)}
    </>
  );
}

export default App;
