export function groupByYear(data) {
    return data.reduce((result, item) => {
        const year = new Date(item.date).getFullYear();
        result[year] = result[year] || [];
        result[year].push(item);
        return result;
    }, {});
}

export function buildDataStackChartInOut(data_in, data_out, groupFunction) {
    const groupedInData = groupFunction(data_in)
    const groupedOutData = groupFunction(data_out)
    const d = []
    Object.entries(groupedInData).forEach(group => {
        d.push({ xAxis: group[0], In: Object.entries(group[1]).map(e => e[1].bytes).reduce((acc, k) => acc + k, 0) })
    });
    Object.entries(groupedOutData).forEach(group => {
        if (d.find(entry => entry.xAxis === group[0])) {
            let i = d.findIndex(entry => entry.xAxis === group[0])
            d[i] = { xAxis: group[0], In: d[i].In, Out: Object.entries(group[1]).map(e => e[1].bytes).reduce((acc, k) => acc + k, 0) }
        }
    })

    return d
}

export function buildDataPieChartInOut(data_in, data_out) {
    const d = [
        { asset: 'Data In', amount: data_in.map(e => e.bytes).reduce((acc, k) => acc + k, 0), yield: 1 },
        { asset: 'Data Out', amount: data_out.map(e => e.bytes).reduce((acc, k) => acc + k, 0), yield: 2 },
    ]
    return d
}

export function buildDataPieChartQueries(logs) {
    const data = [
        { asset: 'SELECT', amount: 0, yield: 1 },
        { asset: 'CREATE', amount: 0, yield: 2 },
        { asset: 'UPDATE', amount: 0, yield: 3 },
        { asset: 'DROP', amount: 0, yield: 4 },
        { asset: 'DELETE', amount: 0, yield: 5 },
        { asset: 'ALTER', amount: 0, yield: 6 },
        { asset: 'INSERT', amount: 0, yield: 7 },
    ]

    const prepData = logs.map(log => log.query.split(" ")[0].toUpperCase())
    var sum = 0
    prepData.forEach(query => {
        switch (query) {
            case 'SELECT':
                data[0].amount += 1
                ++sum
                break;
            case 'CREATE':
                data[1].amount += 1
                ++sum
                break;
            case 'UPDATE':
                data[2].amount += 1
                ++sum
                break;
            case 'DROP':
                data[3].amount += 1
                ++sum
                break;
            case 'DELETE':
                data[4].amount += 1
                ++sum
                break;
            case 'ALTER':
                data[5].amount += 1
                ++sum
                break;
            case 'INSERT':
                data[6].amount += 1
                ++sum
                break;
            default:
                break;
        }
    })
    data.forEach(d => d.amount = d.amount / sum)
    return data
}

export var statisticsTheme = {
    baseTheme: 'ag-default',
    palette: {
        fills: ['#071F5A', '#0e3fff', 'green', 'red', 'yellow', 'black', 'blue'],
        strokes: ['black'],
    },
    overrides: {
        common: {
            title: {
                fontSize: 24,
            },
        },
        bar: {
            series: {
                label: {
                    enabled: true,
                    color: 'white',
                },
            },
        },
    },
}