import { Link } from "react-router-dom";
import updateHead from "../../utils/helper/updatePageHead"
import Calendly from "./tmpl/Calendly";
import ContactForm from "./tmpl/ContactForm";

export default function Contact() {
    const title = "DIPS | Contactez-nous";
    const cssPath = "/style/contact.css";

    return (
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            <section className="section" >
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-columns-layout" grid-template="1,1">
                            <div className="section-vertical-centered-layout">
                                <section className="section-content-container">
                                    <header>
                                        <h1 className="section-content-title">Aidez-nous à résoudre vos problèmes</h1>
                                    </header>
                                    <p className="section-content-description">
                                        Vous souhaitez des informations, vous avez besoin d'aide sur nos produits, vous avez des questions, une idée
                                        de projet ou tout simplement envie de découvrir l'IBMi de la meilleure façon avec Peasys ? Contactez notre équipe d'experts
                                        et nous reviendrons vers vous au plus vite.
                                    </p>
                                    
                                    <Link to='/appointement-schedule'>
                                        <button className="dark-bg">Entretien visio-conférence</button>
                                    </Link>
                                </section>
                            </div>

                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
        </>)
}