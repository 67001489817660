export function checkEmptyInput(value) {
    return !value ? "Les champs * doivent être renseignés" : ""
}

export function checkNumberInput(value) {
    return isNaN(parseInt(value)) ? "Le nombre n'est pas valide" : ""
}

export function checkMailInput(value) {
   return !value.split('').includes('@') ? "(e-mail non valide)" : ""
}

export function disableButton(user) {
    return !user.lastname || !user.firstname || !user.mail || !user.phone || !user.password
}