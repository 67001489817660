import React, { createContext, useContext, useState, useEffect} from "react";

const UserContext = createContext();

export const useUser = () => { 
    return useContext(UserContext) 
};

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({
        _id:"",
        lastname:"",
        firstname:"",
        mail:"",
        phone:"",
        company:"",
        license_keys:  []
    })

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if(storedUser)setUser(JSON.parse(storedUser))
    }, [])

    const loginLocalUser = (user, authToken) => {
        setUser({ ...user, token: authToken })
        localStorage.setItem('user', JSON.stringify({ ...user, token: authToken }))
    }

    const updateLocalUser = (updatedUser) => {
        setUser(updatedUser)
        localStorage.setItem('user', JSON.stringify(updatedUser))
    }

    const logoutLocalUser = () => {
        setUser({
            _id:"",
            lastname:"",
            firstname:"",
            mail:"",
            phone:"",
            company:"",
            license_keys:  []
        })
        localStorage.removeItem('user')
    }

    const value = {
        user,
        loginLocalUser,
        updateLocalUser,
        logoutLocalUser
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};