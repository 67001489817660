import updateHead from "../../utils/helper/updatePageHead";
import Calendly from "./tmpl/Calendly";

export default function AppointementSchedule() {
    const title = "DIPS | Réservez un rendez-vous"
    const cssPath = "/style/contact.css"

    return (
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            <section className="section" >
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-columns-layout" grid-template="1,1">
                            <div className="section-vertical-centered-layout">
                                <section className="section-content-container">
                                    <header>
                                        <h1 className="section-content-title">Réservez un rendez-vous avec notre équipe</h1>
                                    </header>
                                    <p className="section-content-description">
                                        Ce rendez-vous de 30 minutes est le premier point de contact entre nous. Il va servir à la découverte de vos besoins
                                        et en découlera la réalisation d'un devis.
                                    </p>
                                </section>
                            </div>

                            <Calendly />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}