export default function DocUseCases() {
    return (
        <>
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <section className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Découvrez des cas pratiques d'utilisation du Service Peasys</h1>
                                <h2 className="section-content-subtitle">Lorem ipsum dolor sit amet consectetur.</h2>
                            </header>
                            <p>
                                Lorem ipsum dolor sit amet consectetur. Amet bibendum at augue turpis cras. In imperdiet ultricies etiam tellus interdum quam adipiscing id nisi.
                                Lorem pharetra amet donec sollicitudin ligula molestie pharetra vestibulum. Orci neque pellentesque facilisis facilisis aliquam.
                            </p>
                        </section>

                        <section id="section-usecase-" className="section-content-container">
                            <h1 className="section-subtitle-special">A venir</h1>
                            <p>
                            </p>
                            {/*<CodeBlockWrapper
                                data={createQuery}
    showLineNumbers={true} />*/}
                        </section>
                    </div>
                </div>
            </section>
        </>
    )
}