export default function KeyPointsDetails() {
    return (
        <div>
            <ul className="section-register-keypoints-list">
                <h2>Créez un compte DIPS et retrouvez toutes vos informations au même endroit.</h2>
                <li key="regitem2" className="section-register-keypoints-item">
                    <img src="/assets/hexagon-check.svg" alt="check" style={{ width: '20px' }} />
                    <header>
                        <h4>L'accès au service Peasys</h4>
                        <h5>Bénéficiez de la possibilté d'intégrer le service Peasys dans vos développement afin d'optimiser l'utilisation de vos données</h5>
                    </header>
                </li>
                <li key="regitem3" className="section-register-keypoints-item">
                    <img src="/assets/hexagon-check.svg" alt="check" style={{ width: '20px' }} />
                    <header>
                        <h4>Toutes vos informations au même endroit</h4>
                        <h5>Pilotez et modifiez vos informations depuis votre compte</h5>
                    </header>
                </li>
                <li key="regitem4" className="section-register-keypoints-item">
                    <img src="/assets/hexagon-check.svg" alt="check" style={{ width: '20px' }} />
                    <header>
                        <h4>Prise de contact privilégié</h4>
                        <h5>Soyez au plus proche de l'équipe DIPS</h5>
                    </header>
                </li>
            </ul>
        </div>
    )
}