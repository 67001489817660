export const PeasysFreeTrial = {
    title: 'Découverte',
    subtitle: "Bénéficiez d'une période d'essai pour découvrir l'outil Peasys.",
    price: 0,
    pricing_plan: '2',
    button_text: 'Accédez à votre clé',
    max_user_count: 1,
    description_title: 'Et gagnez l’accès au service Peasys pour développeurs.',
    descritpion_list: ["2 mois gratuits", "1 connexion", "Accès à la documentation"],
    priceId: ""
}

export const PeasysClassic = {
    title: 'Classique',
    subtitle: "Plongez vous dans l'aventure Peasys au petit format !",
    price: 40,
    pricing_plan: '3',
    button_text: 'Sélectionner',
    max_user_count: 10,
    description_title: 'Et gagnez en aisance avec Peasys.',
    descritpion_list: ["Accès à la documentation","Accès à un logiciel de démo", "jusqu'à 10 connexions simultanées"],
    priceId : "price_1OvfVtI27ExSp7sFffcA8WfX"
}

export const PeasysPremium = {
    title: 'Premium',
    subtitle: 'Passez à la vitesse supérieure avec Peasys taillé sur-mesure',
    price: 0,
    pricing_plan: '4',
    button_text: 'Prendre rendez-vous',
    max_user_count: 1,
    description_title: "Et bénéficiez d'une flexibilité des avantages suivants.",
    descritpion_list: ["Accompagnement personnalisé", "Entretien visio-conférence", "Remise sur volume", "Support inclus", ],
    priceId : ""
}

export const servicePricingInfos = {
    title: 'Nos prestations sur-mesures',
    subtitle: 'Nous mettons à votre service notre expertise métier pour évaluer le budget de vos projets.',
    price: 0,
    pricing_plan: '1',
    button_text: 'Contactez-nous',
    max_user_count: 0,
    description_title: 'Et lançons-nous ensemble dans vos projet',
    descritpion_list: ["Accompagnement personnalisé", "Entretien visio-conférence", "Réalisations de devis"],
    priceId : ""
}

/*
export const devPricingInfos1 = {
    title: 'Développeur #1',
    subtitle: 'Lorem ipsum dolor sit amet consectetur. Cursus nunc nam auctor nulla luctus.',
    price: 4,
    pricing_plan: '1',
    button_text: 'Sélectionner',
    max_user_count: 1,
    description_title: 'Et gagnez l’accès au service Peasys pour développeurs.',
    descritpion_list: ["test1","test2"],
    priceId_recurrent : "price_1OXNKQI27ExSp7sFCDUziVm9"
}
export const devPricingInfos2 = {
    title: 'Développeur #2',
    subtitle: 'Lorem ipsum dolor sit amet consectetur. Cursus nunc nam auctor nulla luctus.',
    price: 6,
    pricing_plan: '2',
    button_text: 'Sélectionner',
    max_user_count: 3,
    description_title: 'Et gagnez l’accès au service Peasys pour développeurs.',
    descritpion_list: ["test1","test2"],
    priceId_recurrent : "price_1ObKLMI27ExSp7sFstfGFXy6"
}
export const devPricingInfos3 = {
    title: 'Développeur #3',
    subtitle: 'Lorem ipsum dolor sit amet consectetur. Cursus nunc nam auctor nulla luctus.',
    price: 10,
    pricing_plan: '3',
    button_text: 'Sélectionner',
    max_user_count: 8,
    description_title: 'Et gagnez l’accès au service Peasys pour développeurs.',
    descritpion_list: ["test1", "test2", "test3"],
    priceId_recurrent : "price_1OXNKQI27ExSp7sFCDUziVm9"
}
export const TPEPricingInfos = {
    title: 'TPE',
    subtitle: 'Lorem ipsum dolor sit amet consectetur. Cursus nunc nam auctor nulla luctus.',
    price: 90,
    pricing_plan: '4',
    button_text: 'Sélectionner',
    max_user_count: 25,
    description_title: 'Et gagnez l’accès au service Peasys pour développeurs.',
    descritpion_list: ["test1", "test2"],
    priceId_recurrent : "price_1OXNKQI27ExSp7sFCDUziVm9"  
}
export const PMEPricingInfos = {
    title: 'PME',
    subtitle: 'Lorem ipsum dolor sit amet consectetur. Cursus nunc nam auctor nulla luctus.',
    price: 125,
    pricing_plan: '5',
    button_text: 'Sélectionner',
    max_user_count: 50,
    description_title: 'Et gagnez l’accès au service Peasys pour développeurs.',
    descritpion_list: ["test1", "test2", "test3"],
    priceId_recurrent : "price_1OXNKQI27ExSp7sFCDUziVm9" 
}
export const entreprisePricingInfos = {
    title: 'Entreprise',
    subtitle: 'Lorem ipsum dolor sit amet consectetur. Cursus nunc nam auctor nulla luctus.',
    price: 200,
    pricing_plan: '6',
    button_text: 'Sélectionner',
    max_user_count: 75,
    description_title: 'Et gagnez l’accès au service Peasys pour développeurs.',
    descritpion_list: ["test1", "test2", "test3"],
    priceId_recurrent : "price_1OXNKQI27ExSp7sFCDUziVm9"
}
*/