import updateHead from "../../../utils/helper/updatePageHead";
import CodeBlockWrapper from "../../../components/Utils/CodeBlockWrapper";
import { commandCode } from "../../../data/Documentation/CommandData";

export default function DocIbmiQueries() {
    const title = "DIPS | Documentation Peasys";
    const cssPath = "/style/documentation.css";
    return (
        <>
            {updateHead({ title, cssPath })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <section className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Effectuez des commandes IBMi</h1>
                                <h2 className="section-content-subtitle">Et administrez votre serveur depuis un programme externe.</h2>
                            </header>
                            <div className="section-columns-layout section-content-description" grid-template="1,1" style={{gap:'4em'}}>
                                <p>
                                    Le service Peasys donne également la possibilité d'envoyer des commandes OS/400 depuis le client afin de les
                                    executer sur le serveur. <br/>Grâce à cette fonctionnalité, vous êtes désormais en capacité de développer des programmes 
                                    permettant l'admnistration de vos serveurs IBMi.
                                </p>
                                <p>
                                    De part sa conception et sa connexion au serveur avec un profil (et ses droits) existant sur la machine, l'aspect sécurité 
                                    est mis au premier plan. 
                                </p>
                            </div>
                        </section>

                        <section id="section-ibm" className="section-content-container">
                            <h1 className="section-subtitle-special">Executez une commande OS/400</h1>
                            <p>
                                Le format de retour est sous la forme d'une liste de messages retournés par le serveur lors de l'exécution de la commande. Chaque 
                                message a le format suivant : "CP*xxxx Description du message".
                            </p>
                            <CodeBlockWrapper
                                data={commandCode}
                                showLineNumbers={true} />
                        </section>
                    </div>
                </div>
            </section>
        </>
    )
}