import { useState } from 'react'
import { checkEmptyInput } from "../../../utils/helper/inputHelper";
import { checkLogin, updateUser } from '../../../utils/api/userFetchs';
import bcrypt from "bcryptjs-react"
import { useUser } from '../../../utils/contexts/userContext';
import { useNavigate } from 'react-router-dom';


export default function ResetPassword({ email }) {
    const navigate = useNavigate();
    const { loginLocalUser } = useUser();

    const [password, setPassword] = useState('')
    const [secondPassword, setSecondPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    function handlePasswordReset(e) {
        e.preventDefault();

        if (!password) {
            setErrorMessage("Le nouveau mot de passe ne peut pas être vide.")
            return
        }
        if (password !== secondPassword) {
            setErrorMessage("Les deux mots de passe ne correspondent pas.")
            return
        }

        bcrypt.genSalt(password.size).then((salt) => {
            bcrypt.hash(password, salt).then((pwd) => {
                updateUser({ mail: email, password: pwd })
                checkLogin({ mail: email, password: password }).then((sessionInfos) => {
                    loginLocalUser(sessionInfos.user, sessionInfos.token)
                    navigate('/')
                })
            })
        })
    }

    return (
        <section className="section">
            <div className="section-container">
                <div className="section-container-layout">
                    <div className="section-row-layout section-info-container centered-info-container">
                        <img src="/assets/logo_dips.svg" alt="company logo" className="icon logo-full" />
                        <div className="section-centered-layout">
                            <form className="section-row-layout" onSubmit={handlePasswordReset}>
                                <div>
                                    <label>Entrez votre nouveau mot de passe</label>
                                    <input
                                        type="password"
                                        name="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                                </div>
                                <div>
                                    <label>Confirmer le nouveau mot de passe</label>
                                    <input
                                        type="password"
                                        name="password"
                                        onChange={(e) => setSecondPassword(e.target.value)}
                                        onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                                </div>
                                <div>
                                    <p className='error-text'>{errorMessage}</p>
                                    <button className="dark-bg">Modifier le mot de passe</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}