import updateHead from "../../../utils/helper/updatePageHead";
import { useUser } from "../../../utils/contexts/userContext";
import { Link } from 'react-router-dom';
import { useState } from "react";

export default function AccountDashboard() {
    const title = "DIPS | Votre tableau de bord";

    const { user } = useUser();
    const [copied, setCopied] = useState(false)

    function copyToClipboard() {
        navigator.clipboard.writeText(user._id)
        setCopied(true)
    }

    return (
        <>
            {updateHead({ title })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-row-layout">
                            <div className="section-columns-layout" grid-template="1,1">
                                <div className="section-info-container">
                                    <section className="section-content-container" style={{ margin: '0' }}>
                                        <h2 style={{ margin: '0.5em 0' }}>Un besoin ?</h2>
                                        <p className="section-content-description">
                                            Visitez les différentes pages présentant nos solutions et touvez celle qui vous convient
                                        </p>
                                        <Link to='/services' style={{ width: 'fit-content' }}>
                                            <button className="dark-bg">Nos solutions</button>
                                        </Link>
                                    </section>
                                </div>

                                <div className="section-info-container">
                                    <section className="section-content-container" style={{ margin: '0' }}>
                                        <h2 style={{ margin: '0.5em 0' }}>Une question ?</h2>
                                        <p className="section-content-description">
                                            N’attendez pas plus et remplissez un formulaire de contact avec toutes vos demandes
                                        </p>
                                        <Link to='/contact' style={{ width: 'fit-content' }}>
                                            <button className="dark-bg">Contactez-nous</button>
                                        </Link>
                                    </section>
                                </div>
                            </div>

                            <div className="section-info-container" style={{ width: 'fit-content' }}>
                                <h2>Vos Informations</h2>

                                <div className="section-columns-layout" grid-template="1,1,1">
                                    <div className="section-account-personal-infos-container">
                                        <span><strong>Nom</strong>{user.lastname}</span>
                                        <span><strong>Prénom</strong>{user.firstname}</span>
                                    </div>

                                    <div className="section-account-personal-infos-container">
                                        <span><strong>Mail</strong>{user.mail}</span>
                                        <span><strong>Téléphone</strong>{user.phone}</span>
                                    </div>

                                    <div className="section-account-personal-infos-container">
                                        <span>
                                            <strong>ID Client</strong>
                                            <span className="license-key-container" onClick={() => copyToClipboard()}>{user._id}</span>
                                            <span className="license-key-copy-container">{copied ? "Copié" : "Cliquez pour copier"}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}