import React, { useEffect, useState } from "react";
import updateHead from "../../utils/helper/updatePageHead";
import { Link } from "react-router-dom";
import { useUser } from "../../utils/contexts/userContext";

export default function NavBarCollapsed() {
    const cssPath = "/style/navbar.css";

    const { user } = useUser();
    const [dropDown, setDropDown] = useState(false)

    useEffect(() => {
        const handleClick = (e) => {
            if (e.target.alt !== "menu") {
                setDropDown(false)
            }
        }
        document.body.addEventListener('click', handleClick)

        return () => document.body.removeEventListener('click', handleClick)
    }, [])

    return (
        <>
            {updateHead({ cssPaths: [cssPath] })}
            <div className="navbar-collapsed-container">
                <Link className="navbar-company-icon" to="/">
                    <img src="/assets/logo_dips.svg" className="icon" alt="company logo" style={{ width: '100px' }} />
                </Link>
                {dropDown && <div className="navbar-collapsed-container-rightpart">
                    <div className="navbar-collapsed-menu-item">
                        <Link to="/">
                            <img src="/assets/logo_dips.svg" className="icon" alt="company logo" style={{ width: '100px' }} />
                        </Link>
                    </div>
                    <Link className="navbar-collapsed-menu-item" to="/services">Prestations</Link>
                    <Link className="navbar-collapsed-menu-item" to="/library">Service Peasys</Link>
                    <Link className="navbar-collapsed-menu-item" to="/products">Produits</Link>
                    <Link className="navbar-collapsed-menu-item" to="/docs">Documentation</Link>
                    <Link className="navbar-collapsed-menu-item" to="/pricing">Tarifs</Link>
                    <Link className="navbar-collapsed-menu-item" to="/company">Entreprise</Link>
                    {user._id ?
                        (<Link className="navbar-menu-item navbar-account-info-container" to="/account">
                            {/*<a href="https://fr.freepik.com/search?format=search&freeSvg=free&last_filter=freeSvg&last_value=free&query=company&type=icon">Icône de UIcons</a>*/}
                            <img alt="profile" src="/assets/company.svg" />
                            <h6>{user.firstname} {user.lastname}</h6>
                        </Link>) :
                        (<>
                            <Link
                                className="navbar-collapsed-menu-item login"
                                to="/register">Inscription</Link>
                            <Link
                                className="navbar-collapsed-menu-item register"
                                to="/login">Connexion</Link>
                        </>)}
                </div>}
                <img
                    src="/assets/menu.png"
                    className="navbar-collapsed-icon icon"
                    alt="menu" onClick={() => setDropDown(!dropDown)} />
            </div>
        </>
    )
}