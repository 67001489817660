import updateHead from "../../utils/helper/updatePageHead";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import ServicesCarousel from "./tmpl/ServicesCarousel";
import { AnalysisServices, DevServices, SupportServices } from "../../data/Services/ServicesData";

export default function Services() {
    const title = "DIPS | Découvrez toutes nos prestations";
    const cssPath = "/style/services.css";

    return (
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-centered-layout">
                            <div className="section-content-container">
                                <header>
                                    <h1 className="section-content-title">Nos services</h1>
                                    <h2 className="section-content-subtitle">Et nos différentes manières de vous aider...</h2>
                                </header>
                                <div>
                                    <p className="section-content-description">
                                        La <strong>société DIPS</strong> s'est construite grâce à sa clientèle autour de ses multiples prestations et 
                                        bénéficie aujourd'hui de plus de <strong>20 ans d'expérience</strong> dans le développement de logiciel métier. 
                                        Alliant une vision moderne de l'informatique ainsi qu'une
                                        conception fidèle des métiers, DIPS propose son expertise au travers de différentes prestations.
                                    </p>
                                    <p className="section-content-description">
                                        Plus que jamais, DIPS souhaite <strong>ouvrir les portes de l'IBMi</strong> au grand nombre en proposant des 
                                        services pour chaque étape de vos projets ainsi que des <Link to='/products'>logiciels métier</Link>. Confiance 
                                        et collaboration sont les deux maîtres mots des services de DIPS.
                                    </p>
                                </div>
                                <div className="section-content-links-container">
                                    <Link to="/contact">
                                        <button className="dark-bg">Contactez-nous</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section dark-bg">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-columns-layout section-services-services-container" grid-template="1,1,1">
                            <div>
                                <ServicesCarousel dataList={AnalysisServices} sens={1}/>
                                <h2 className="section-content-title" style={{ textAlign: 'center', color: 'white' }}>Analyse</h2>
                            </div>
                            <div>
                                <h2 className="section-content-title" style={{ textAlign: 'center', color: 'white' }}>Développement</h2>
                                <ServicesCarousel dataList={DevServices} sens={-1}/>
                            </div>
                            <div>
                                <ServicesCarousel dataList={SupportServices} sens={1}/>
                                <h2 className="section-content-title" style={{ textAlign: 'center', color: 'white' }}>Support</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}