import updateHead from "../../utils/helper/updatePageHead";
import MemberCard from "./tmpl/MemberCard";
import Footer from "../Footer/Footer";

export default function Company() {
    const title = "DIPS | Entreprise";
    const cssPath = "/style/company.css";

    return (
        <>
            <div style={{ position: 'relative' }}>
                {updateHead({ title, cssPaths: [cssPath] })}
                <section className="section dark-bg">
                    <div className="section-container">
                        <div className="section-container-layout">
                            <div className="section-centered-layout">
                                <div className="section-content-container">
                                    <header>
                                        <h1 className="section-content-title">L'histoire</h1>
                                        <h2 className="section-content-subtitle white-before">De sa génèse...</h2>
                                    </header>
                                    <div className="section-content-description">
                                        <p>
                                            Depuis 2000, DIPS développe des outils personnalisés de gestion dans l'environnement IBM i. Elle intervient dans
                                            l'ensemble de vos projets informatiques : l'analyse, la création de programmes, l'installation, la formation et 
                                            le conseil. DIPS intervient aussi dans vos applications existantes avec pour but de créer des programmes 
                                            spécifiques à votre métier et les intégrer au sein de vos applicatifs.
                                        </p>
                                        <p>
                                            Grâce à notre expérience du terrain, nous vous apportons des solutions à vos projets. Notre objectif est de 
                                            vous fournir un véritable service, de la valeur ajoutée à vos réalisations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section ">
                    <div className="section-container">
                        <div className="section-container-layout section-company-members-container">
                            <div className="section-columns-layout" grid-template="1,2">
                                <header>
                                    <h1 className="section-content-title">L'équipe, des <span className="highlight-title">acteurs</span> de l'écosystème</h1>
                                    <p>
                                        DIPS réunit en son sein des profils diverses et variés lui apportant un large panel de compétences. Coordonné
                                        par Marc, l'équipe se développe dans une atmosphère bienveillante facilitant la communication interne.
                                    </p>
                                </header>

                                <div className="section-company-membercards-container">
                                    <div className="section-columns-layout" grid-template="1,1,1" style={{ gap: '12px' }}>
                                        <MemberCard
                                            name={'Nadine Gauthier'}
                                            imagePath={"/assets/profile_picture.jpg"}
                                            title={"Experte IBMi"}
                                            description={"\"Chaque jour, il faut danser, fût-ce seulement par la pensée.\" (N. de Braslav)"} />
                                        <div className="section-row-layout" style={{ gap: '12px' }}>
                                            <MemberCard
                                                name={'Rémy Molliex'}
                                                imagePath={"/assets/profile_picture.jpg"}
                                                title={"Développeur C# et développeur d'idées"}
                                                description={"\"L'imagination est plus importante que le savoir.\" (A.Einstein)"} />
                                            <MemberCard
                                                name={'Nicolas Cuveillier'}
                                                imagePath={"/assets/profile_picture.jpg"}
                                                description={"\"La vie, c'est comme une boîte de chocolat, on ne sait jamais sur quoi on va tomber.\" (F.Gump)"} />
                                        </div>
                                        <MemberCard
                                            name={'Marc Gabriel'}
                                            imagePath={"/assets/profile_picture.jpg"}
                                            title={"Fondateur"}
                                            description={"\"L'univers de chacun est universel.\" (E. Ionesco)"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section dark-bg">
                    <div className="section-container">
                        <div className="section-container-layout">
                            <div className="section-centered-layout">
                                <div className="section-content-container">
                                    <header>
                                        <h1 className="section-content-title">Et l'avenir</h1>
                                        <h2 className="section-content-subtitle white-before">De cette entreprise à taille humaine</h2>
                                    </header>
                                    <div className="section-content-description">
                                        <p>
                                            L’avenir de DIPS dépend de sa capacité d’innover, de s’adapter aux permanentes évolutions technologiques, 
                                            de répondre aux besoins de ses clients. DIPS développe l’outil Peasys qui s’inscrit dans cette démarche et 
                                            assure l’avenir des entreprises qui possèdent un IBMi et qui n’ont plus d’offres logiciel innovantes.
                                        </p>
                                        <p>
                                            L’avenir de DIPS est lié aussi à son environnement humain. Il se caractérise par une pluralité des expériences, 
                                            une différence générationnelle enrichissante et une vision collaborative des projets.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </>
    )
}