export default function PackCard({ data }) {
    return (
        <div className="section-info-container">
            <header>
                <h2 className="section-pack-card-title">{data.title}</h2>
                <p className="section-pack-card-description">{data.description}</p>
            </header>
            <div style={{ margin: '6px 0 0 0' }}>
                <span style={{ fontSize: '25px' }}>{data.price}€</span>
                <span style={{ fontSize: '12px' }}> HT/{data.duration}</span>
            </div>
        </div>
    )
}