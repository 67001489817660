import React from "react";
import Helmet from "react-helmet"

export default function updateHead({title, cssPaths}) {
    return(
        <>
            <Helmet>
                {title && <title>{title}</title>}
                {cssPaths && cssPaths.map((path, index) => (<link key={path + index} rel="stylesheet" href={path}/>))}
            </Helmet>
        </>
    );
}