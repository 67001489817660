export default function GeneralConditions() {
    return (
        <section className="section">
            <div className="section-container">
                <div className="section-container-layout">
                    <h1 className="section-subtitle-special">Informations Générales</h1>
                    <p>
                        Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, vous bénéficiez d’un droit d’accès et de
                        rectification aux informations qui vous concernent, que vous pouvez exercer en vous adressant à dips@dips400.com.

                    </p>
                    <h1 className="section-subtitle-special">Société</h1>
                    <p>
                        En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs
                        du site https://www.dips400.com l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
                        <br />
                        <br />

                        DIPS
                        <br />
                        125 rue du Placyre
                        <br />
                        38500 VOIRON, France
                        <br />
                        SARL
                        <br />
                        SIRET 434 123 519 00028 | Ape 5829C
                        <br />
                        <br />

                        Téléphone: +33 (0) 4 76 67 71 24
                        <br />
                        Email: dips@dips400.com
                        <br />
                        <br />

                        Les coordonnées ci-dessous ne concerne que les demandes administratives, pour toute demande technique seul le support pourra répondre
                        à vos demandes.
                    </p>
                    <h1 className="section-subtitle-special">Crédits</h1>
                    <p>
                        Tous les icônes on été réalisés par <a href="https://www.freepik.com/" style={{display:'inline-block'}}> Freepik</a>. Tous 
                        les textes, les animations et le logo de DIPS sont la propriétés de DIPS.
                    </p>
                </div>
            </div>
        </section>
    )
}